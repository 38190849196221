import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import { Controls } from '../controls/Controls';
import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';

export default function DropdownSetting({
  setting, options, contextType, setSuccessMessage, refreshParent, setErrorMessage, isMultiple,
}) {
  const { siteId, customerId } = useParams();
  const initialValue = setting.value
    ? setting.value.split(',').map((item) => item.trim())
    : [];
  const [selectedOptions, setSelectedOptions] = useState(
    isMultiple ? initialValue : (initialValue[0] || ''),
  );
  const apiClient = useApiClient();

  const formatSelectedOptions = (selected) => (isMultiple ? selected.join(', ') : selected);

  const handleChange = async (e) => {
    const { value } = e.target;
    setSelectedOptions(value);
  };

  const handleBlur = async () => {
    const formattedValue = formatSelectedOptions(selectedOptions);
    const isEmpty = isMultiple ? selectedOptions.length === 0 : selectedOptions === '';
    try {
      if (contextType === 'customer') {
        if (isEmpty) {
          // Delete customer setting
          await apiClient.deleteCustomerSetting(customerId, setting.name, setting.category);
        } else {
          await apiClient.updateCustomer(
            customerId,
            { ...setting, value: formattedValue },
          );
        }
      } else if (contextType === 'site') {
        if (isEmpty) {
          // Delete site setting
          await apiClient.deleteSiteSetting(siteId, setting.name, setting.category);
        } else {
          await apiClient.updateSiteSetting(
            siteId,
            { ...setting, value: formattedValue },
          );
        }
      }
      setSuccessMessage(`${setting.name} updated successfully`);
      refreshParent();
    } catch (error) {
      setErrorMessage(convertAxiosErrorToUserFacingMessage(error));
    }
  };

  useEffect(() => {
    const parsedValues = setting.value
      ? [...new Set(setting.value.split(',').map((item) => {
        const trimmed = item.trim();
        if (trimmed.toLowerCase() === 'fr_ca') {
          return 'fr_CA';
        }
        return trimmed.toLowerCase();
      }))]
      : [];
    if (isMultiple) {
      setSelectedOptions(parsedValues);
    } else {
      const singleValue = parsedValues.length > 0 ? parsedValues[0] : '';
      setSelectedOptions(singleValue);
    }
  }, [setting.value, isMultiple]);

  return (
    <Controls.Select
      sx={{ minWidth: '200px', maxWidth: '400px' }}
      multiple={isMultiple}
      value={selectedOptions}
      label="value"
      onChange={handleChange}
      onBlur={handleBlur}
      renderValue={(selected) => {
        if (isMultiple) {
          return Array.isArray(selected) ? selected.join(', ') : selected;
        }
        return selected;
      }}
    >
      {options && options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Controls.Select>
  );
}

DropdownSetting.propTypes = {
  setting: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ]),
  ).isRequired,
  contextType: PropTypes.oneOf(['site', 'customer']).isRequired,
  refreshParent: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  setErrorMessage: PropTypes.func,
  isMultiple: PropTypes.bool.isRequired,
};

DropdownSetting.defaultProps = {
  refreshParent: () => {},
  setErrorMessage: () => {},
  setSuccessMessage: () => {},
};
